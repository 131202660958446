import TinyDatePicker from "tiny-date-picker";
import axios from "axios";
import PerfectScrollbar from "perfect-scrollbar";

var indexCalendar = new Vue({
    el: '.permanent-cal-container',
    data: {
        dpPermanent: null,
        year: 0,
        month: 0,
        day: 0,
        last_day_of_month: 0,
        monthly_events_date: null,

        //for getting events
        api_get_event_route: null,
        api_get_event_state: true,
        csrf_field: null,
    },
    methods: {
        calendarOnChange: function(calendar_state, show_monthly_all) {

            console.log(show_monthly_all);
            this.year = calendar_state.hilightedDate.getFullYear();
            this.month = calendar_state.hilightedDate.getMonth()+1;
            this.day = calendar_state.hilightedDate.getDate();

            console.log(calendar_state.hilightedDate);
            $('.month-right').find('.month-event').remove();
            $('.month-right').append(
                '<div class="lds-grid-wrapper">' +
                    '<div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>' +
                '</div>'
            );

            if(this.api_get_event_state === true) {
                this.api_get_event_state = false;
                axios.post(this.$el.getAttribute('api_get_event_route'), {
                    csrf_token: this.csrf_field,
                    year: this.year,
                    month: this.month,
                    day: this.day,
                    show_monthly_all: show_monthly_all
                })
                    .then((response) => {
                        console.log(response);
                        this.processEventData(response);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                ;
            }
        },
        processEventData: function(response){
            console.log(response);
            $('.month-right').find('.lds-grid-wrapper').remove();
            var appender = '';
            var items = response.data.value;
            if(items.length > 0) {
                for (var i = 0; i < items.length; i++) {
                    var css_classes = ['date1', 'date2', 'date3'];
                    var ran = Math.floor((Math.random() * css_classes.length));
                    var template =
                        '                <a href="'+items[i].event_route+'" class="month-event">\n' +
                        '                    <div class="evn-date ' + css_classes[ran] + ' ">\n' +
                        '                        <div class="date">\n' +
                        '                            <h2>' + items[i].current_event_date[2] + '</h2><h3>/' + items[i].current_event_date[1] + '</h3></div>\n' +
                        '                        <h3>(' + items[i].date_of_week + ')</h3>\n' +
                        '                    </div>\n' +
                        '                    <div class="evn-detail">\n' +
                        '                        <h4>' + items[i].event_title + '</h4>\n' +
                        '                        <div class="evn-tag">\n' +
                        '                            <h4>' + items[i].event_subtype + '</h4>\n' +
                        '                            <h4>' + items[i].event_language + '</h4>\n' +
                        '                        </div>\n' +
                        '                        <div class="evn-price">\n' +
                        '                            <h4>' + items[i].event_price + '</h4>\n' +
                        '                            <h4>' + items[i].is_included_entry_fee_msg + '</h4>\n' +
                        '                        </div>\n' +
                        '                    </div>\n' +
                        '                </a>'
                    ;
                    appender += template;
                }
            } else {
                appender =
                    '                <div class="month-event" style="display: flex; justify-content: center;">\n' +
                    '                    <div class="evn-detail">\n' +
                    '                        <h4>'+ response.data.date_no_event +'</h4>\n' +
                    '                        <div class="evn-tag">\n' +
                    '                            <a class="btn show_monthly_all" href="#" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample" id="click">\n' +
                    '                               '+ response.data.show_monthly_all_events +'\n' +
                    '                            </a>\n' +
                    '                            <h4>&nbsp;</h4>\n' +
                    '                        </div>\n' +
                    '                        <div class="evn-price">\n' +
                    '                            <h4>&nbsp;</h4>\n' +
                    '                            <h4>&nbsp;</h4>\n' +
                    '                        </div>\n' +
                    '                    </div>\n' +
                    '                </div>'
                ;
            }
            $('.month-right').prepend(appender);
            this.api_get_event_state = true;
            this.setHighLightedDates(response.data.monthly_events_date);
            $('.month-right').scrollTop(0);
        },
        setHighLightedDates: function(monthly_events_date){
            var days = $('.dp-day');
            for(var i = 0; i < days.length; i++){
                if(monthly_events_date.indexOf(parseFloat($(days[i]).attr('data-date'))) > -1){
                    $(days[i]).addClass('dp-selected');
                }
            }
        }
    },
    mounted: function(){
        this.year = this.$el.getAttribute("year");
        this.month = this.$el.getAttribute("month");
        this.day = this.$el.getAttribute("day");
        this.last_day_of_month = this.$el.getAttribute("last_day_of_month");
        this.monthly_events_date = JSON.parse(this.$el.getAttribute('monthly_events_date'));

        this.api_get_event_route = this.$el.getAttribute("api_get_event_route");
        this.csrf_field = this.$el.getAttribute("csrf_token");

        //initialize calendar
        var dpPermanent = TinyDatePicker('.permanent-cal-container', {
            mode: 'dp-permanent',
            // min: this.year+'-'+this.month+'-'+'1',
            // max: this.year+'-'+this.month+'-'+this.last_day_of_month,
        })
        .on({
            //register calendar change callback
            statechange: (_, dp) => this.$emit('calendarOnChange', dp.state, false),
        })
        ;
        this.dpPermanent = dpPermanent;

        //register vue event listener
        this.$on('calendarOnChange', function(calendar_state, show_monthly_all){
            this.calendarOnChange(calendar_state, show_monthly_all);
        });

        this.calendarOnChange(this.dpPermanent.state, true);
        // this.setHighLightedDates();
    }
});

const ps = new PerfectScrollbar('.month-right', {
    wheelSpeed: 2,
    wheelPropagation: false,
    minScrollbarLength: 20
});

$(document).on('click', '.show_monthly_all', function(){
    indexCalendar.$emit('calendarOnChange', indexCalendar.dpPermanent.state, true);
});
//testing
// console.log(dpPermanent.state.hilightedDate);
// console.log(dpPermanent.state.hilightedDate.getFullYear());
// console.log(dpPermanent.state.hilightedDate.getMonth());
// console.log(dpPermanent.state.hilightedDate.getDate());